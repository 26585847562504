const formatYear = (start: number, end: number) => {
  if (start === end) return start;
  return `${start} to ${end}`;
};

type RequiredItemsType = "RD" | "CS" | "45L" | "EN";

export const getRequiredItems = (
  type: RequiredItemsType,
  start: number,
  end: number,
) => {
  if (type === "CS") {
    return [
      {
        title: "Property Settlement Statement",
        description:
          "Final statement of the terms and costs of the property transaction.",
        required: true,
      },
      {
        title: "Purchase/Lease agreement or appraisals",
        description:
          "Formal documents detailing the terms of purchase or lease and property value assessments.",
        required: true,
      },
      {
        title: "Contracts/construction schedules",
        description:
          "Agreements and timelines related to the construction or renovation of the property.",
        required: true,
      },
      {
        title: "Payment schedules",
        description:
          "Detailed timelines for payments due over the course of the project.",
        required: true,
      },
      {
        title: "Blueprint of prospect project",
        description:
          "Architectural plans and design specifications for the proposed construction.",
        required: false,
      },
      {
        title: "Certificate of Occupancy",
        description:
          "Document issued by a local government agency certifying a building's compliance with applicable building codes and other laws.",
        required: false,
      },
      {
        title: "Applicable site inspections",
        description:
          "Reports from inspections conducted throughout the construction process for compliance and quality assurance.",
        required: false,
      },
      {
        title:
          "Applicable county documents that will assist in calculating land and real property book value",
        description:
          "Official county records required for determining the value of land and real estate assets for accounting purposes.",
        required: false,
      },
      {
        title: "Detailed accounting of interior finishes",
        description:
          "An itemized list of interior finish materials, costs, and suppliers.",
        required: false,
      },
      {
        title:
          "Documentation that record or proves deterioration and/or removal of preexisting assets",
        description:
          "Records or evidence showing the condition and removal of assets prior to new construction or renovation.",
        required: false,
      },
    ];
  } else if (type === "45L") {
    return [
      {
        title: "Project List",
        description:
          "A comprehensive list of all projects undertaken within the specified period, including brief descriptions and status updates.",
        required: true,
      },
      {
        title: "Building Drawings",
        description:
          "Architectural and engineering drawings detailing the design, dimensions, and construction specifics of buildings involved in the projects.",
        required: true,
      },
      {
        title: "Project Costing",
        description:
          "Detailed breakdown of all costs associated with each project, including materials, labor, and overhead expenses, to provide insights into budget allocation and financial planning.",
        required: true,
      },
      {
        title: "Contractor Invoices",
        description:
          "Copies of all invoices and payments to contractors, providing a record of financial transactions and services rendered in relation to the projects.",
        required: false,
      },
    ];
  } else if (type === "EN") {
    return [
      {
        title: "Building Floor Plans",
        description: "",
        required: true,
      },
      {
        title: "Elevations",
        description: "",

        required: true,
      },
      {
        title: "Envelope Summary",
        description:
          "Wall type, roof type, and floor type can be used if the envelope summary is not available.",
        required: true,
      },
      {
        title: "Window thermal performance",
        description: "",
        required: true,
      },
      {
        title: "Lighting Power Density",
        description: "",
        required: true,
      },
      {
        title: "HVAC equipment schedule",
        description: "",
        required: true,
      },
      {
        title: "Full architectural plans",
        description:
          "These include envelope thermal performance and window specifications",
        required: true,
      },
      {
        title: "Full MEP plans",
        description:
          "These include mechanical equipment specifications and schedules, DHW system specifications and schedules equipment, lighting power density, design, and specifications for all the spaces",
        required: true,
      },
    ];
    return;
  } else {
    return [
      {
        title: "Project Listing",
        description:
          "Please provide a list of all the research projects conducted by year. In addition, we need a short description of each project and a cost of the project.",
        required: true,
        id: "project-listing",
      },
      {
        title: "Employee/Wage Data",
        description:
          "Please provide the W-2 Box 1 wage data for every employee in the company for the study years. Also, include the job description and department for each employee.",
        required: true,
        id: "employee-wage-data",
      },
      {
        title: "Supply Costs",
        description:
          "These are expenses incurred for purchasing materials or resources necessary for day-to-day operations or project completion.",
        required: true,
        id: "supply-costs",
      },
      {
        title: "1099/Contractor Costs",
        description:
          "These costs represent payments made to independent contractors or freelancers who are hired to perform specific tasks or services.",
        required: true,
        id: "contractor-costs",
      },
      {
        title: "Computer Lease Costs",
        description:
          "This includes the recurring payments for leasing computer equipment, such as laptops, desktops, or servers, used for business operations.",
        required: true,
        id: "computer-lease-costs",
      },
      {
        title: "Tax Returns - last 3 years",
        description:
          "If you have claimed the credit in the past, please provide the tax returns that have been filed for the prior 3 years. If we are computing for multiple years please provide the returns for all study years.",
        required: true,
        id: "tax-returns",
      },
      {
        title: "Research documents",
        description:
          "Please provide documents related to your research projects. The most useful documents are project requirement or specification documents, test scripts and test results, team notes, etc.",
        required: false,
        id: "research-documents",
      },
      {
        title: "Company ownership schedule",
        description:
          "We understand your company is a standalone entity with no subsidiaries. If that is NOT the case, please send an ownership schedule showing all companies in the controlled group (parent company, subsidiaries, other connected entities, etc.).",
        required: false,
        id: "company-ownership-schedule",
      },
      {
        title: "Contracts related to research",
        description:
          "Please provide any contracts specifically related to research projects if relevant.",
        required: false,
        id: "contracts-related-to-research",
      },
    ];
  }
};
